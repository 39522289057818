import ChevronLeftIcon from '../../assets/icons/chevron-left.svg';
import ChevronRightIcon from '../../assets/icons/chevron-right.svg';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react'

const CrabSelector = ({
  crabData,
  dropdownData,
  image,
  nextCrab,
  previousCrab,
  theme
}) => (
  <div className='
    lg:flex
    items-center
    lg:w-[80%]
    mx-auto
    justify-center
    z-10
  '>
      <div className="
        grid
        grid-cols-12
        py-10
        max-w-lg
        mx-auto
        lg:mx-0
      ">
        <Link
          aria-label='Previous crab'
          to={`/crabs/${previousCrab}`}
          className="
            col-span-3
            self-center
            h-1/4
        ">
          <ChevronLeftIcon className={`
            h-full
            mx-auto
            ${theme === "shrimpocalypse" ? "stroke-white" : "stroke-brown-dark"}
          `}/>
        </Link>
        <div className="
          col-span-6
          drop-shadow-3xl
          flex
          items-center
        ">
          <GatsbyImage
            image={image}
            alt={crabData.fullname}
          />
        </div>
        <Link
          aria-label='Next crab'
          to={`/crabs/${nextCrab}`}
          className="
            col-span-3
            self-center
            h-1/4
        ">
          <ChevronRightIcon className={`
            h-full
            mx-auto
            ${theme === "shrimpocalypse" ? "stroke-white" : "stroke-brown-dark"}
          `}/>
        </Link>
      </div>
    <div className={`
      w-[80%]
      mx-auto
      lg:mx-0
      max-w-lg
      ${theme === "shrimpocalypse" ? "text-white" : "text-brown-dark"}
    `}>
      <h1 className={theme === "shrimpocalypse" ? "text-white" : "text-brown-dark"}>
        {crabData.fullname}
      </h1>
    <p className='
      text-lg
      z-10
    '>
      {crabData.description}
    </p>
    </div>
  </div>
)

export default CrabSelector;
