import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Review = ({ data, reviewData, index }) => {
  const image = getImage(data.allFile.nodes.find(node=>node.name===reviewData.img));
  return (
    <div className='
      bg-white
      rounded-lg
      p-6
      text-sm
      relative
      sm:max-w-lg
      md:max-w-xl
      drop-shadow-3xl
    '>
      <div className="
        float-right
        review-shape
        w-10
        h-10
      "></div>

      <div className="
        rounded-full
        w-20
        h-20
        absolute
        -right-6
        -top-6
        overflow-hidden
        radial
        drop-shadow-3xl
        p-1
      ">
        <GatsbyImage
          image={image}
          alt={reviewData.reviewer}
          className='drop-shadow-hard'
        />
      </div>

      <p className='
        pb-4
        hyphenate
        md:text-xl
        lg:text-2xl
      '>
        "{reviewData.text}"
      </p>

      <p className='
        italic
        md:text-sm
        lg:text-base
      '>
        -{reviewData.reviewer}, {reviewData.organization}
      </p>
    </div>
  )
}

export default Review
