import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const ShrimpShowcase = () => {

  return (
    <section className="
      radial-shrimpocalypse-shrimp
      w-screen
    ">
      <div className="
        lg:py-40
        xl-py-40
        py-16
        px-4
        sm:px-16
        text-2xl
        md:text-3xl
        text-white
        text-center
        flex
        flex-col
        gap-y-4
      ">
        <p className="
          px-4
          max-w-full
          md:max-w-[40rem]
          mx-auto
        ">
          But BEWARE the ruthless <b>Four Horsemen of the Shrimpocalypse</b>! If played in order, whoever plays the 4th Horseman wins instantly!
        </p>
        <div className="
          grid
          grid-cols-2
          sm:grid-cols-4
          max-w-full
          md:max-w-[60rem]
          mx-auto
        ">
          <Link
            to="/crabs/fairy_shrimp"
          >
            <StaticImage
              src="../../images/shrimpocalypse/horseman1.png"
              alt="Fairy Shrimp"
            />
          </Link>

          <Link
            to="/crabs/mantis_shrimp"
          >
            <StaticImage
              src="../../images/shrimpocalypse/horseman2.png"
              alt="Mantis Shrimp"
            />
          </Link>

          <Link
            to="/crabs/pistol_shrimp"
          >
            <StaticImage
              src="../../images/shrimpocalypse/horseman3.png"
              alt="Pistol Shrimp"
            />
          </Link>

          <Link
            to="/crabs/sexy_shrimp"
          >
            <StaticImage
              src="../../images/shrimpocalypse/horseman4.png"
              alt="Sexy Shrimp"
            />
          </Link>
        </div>
      </div>

    </section>
  )
}

export default ShrimpShowcase;