import React from 'react'
import OceanFloorSvg from '../assets/ocean-floor.svg';

const OceanSection = ({ children, theme }) => (
  <section className={`
    pt-8
    bg-gradient-to-b
    ${theme === "shrimpocalypse" ? 
      "from-ocean-light-shrimpocalypse to-ocean-dark-shrimpocalypse"
    :
      "from-ocean-light to-ocean-dark"
    }
  `}>

    {children}

    <OceanFloorSvg className='
      fill-ocean-floor
      md:-mt-20
      lg:-mt-32
      -mb-px
    '/>

  </section>
)

export default OceanSection
