import React from 'react'

const SandSection = ({ children, theme }) => (
  <section className={`
    ${theme === "shrimpocalypse" ? "radial-shrimpocalypse" : "radial-base"}
    w-screen
  `}>
    
  {children}

  </section>
)

export default SandSection;
