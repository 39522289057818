import React from 'react';
import Layout from '../components/Layout';

import {
  getImage
} from 'gatsby-plugin-image';

import CardCombos from '../components/JokerCrab/CardCombos';
import CrabSelector from '../components/JokerCrab/CrabSelector';
import WaveSvg from '../assets/wave-lg.svg'
import OceanFloorSvg from '../assets/ocean-floor.svg';

import YoutubeEmbed from '../components/YoutubeEmbed';
import OceanSection from '../components/OceanSection';
import SandSection from '../components/SandSection';
import Metadata from '../components/Metadata';

const JokerCrab = ({ pageContext }) => {
  const {
    crabData,
    combos,
    nextCrab,
    previousCrab,
    dropdownData,
  } = pageContext;

  const image = getImage(crabData.imageData);

  return (
    <Layout
      theme={crabData.expansion}
    >
      <Metadata
        title={crabData.fullname}
        slug={crabData.name}
        description={crabData.description_short}
        keywords={`${crabData.fullname}, ${crabData.scientific_name}`}
      />
      <SandSection theme={crabData.expansion}>
        <CrabSelector
          crabData={crabData}
          dropdownData={dropdownData}
          image={image}
          nextCrab={nextCrab}
          previousCrab={previousCrab}
          theme={crabData.expansion}
        />

        <div>
          <h2 className={`
            pt-8
            pb-6
            ${crabData.expansion === "shrimpocalypse" ? "text-white" : "text-brown-dark"}
          `}>
            {`${crabData.fullname} In Action:`}
          </h2>
  
          <YoutubeEmbed
            src={crabData.youtube_link}
          />
        </div>

        {combos ?
          <WaveSvg className={`
            ${crabData.expansion === "shrimpocalypse" ? "fill-ocean-light-shrimpocalypse" : "fill-ocean-light"}
            -m-px
          `}/>
        :
          <OceanFloorSvg className='
            fill-ocean-floor
            md:-mt-20
            lg:-mt-32
            -mb-px
            -z-10
          '/>
        }
      </SandSection>
      
      {combos &&
        <OceanSection theme={crabData.expansion}>

          <CardCombos
            crabData={crabData}
            combos={combos}
            image={image}
          />

        </OceanSection>
      }
    </Layout>
  );
}

export default JokerCrab;
