import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const Hero = ({ theme }) => {
  const breakpoints = useBreakpoint();

  const popInsiderUrl = "https://thepopinsider.com/products/crabs-in-a-bucket/";

  const baseGameImgMobile = "../../images/hero-mobile.png";
  const shrimpocalypseImgMobile = "../../images/hero-mobile-shrimpocalypse.png";

  return (
    <header className="">
      {breakpoints.sm ? (
        <div className="relative">
          {theme === "shrimpocalypse" ?
            <StaticImage
              src={shrimpocalypseImgMobile}
              alt="Crabs in a Bucket box art"
            />
            :
            <StaticImage
              src={baseGameImgMobile}
              alt="Crabs in a Bucket box art"
            />
          }
          <div className="badge-mask translate-x-[105%] animate-slide-in-right absolute bottom-[1%] right-[1%] w-[35%]">
            <a
              className="block"
              href={popInsiderUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../../images/popinsider.png"
                placeholder="none"
              />
              <div className="animate-shimmer -translate-x-full absolute top-0 left-0 w-full h-full bg-gradient-to-r from-[rgba(0,0,0,0)] from-40% via-[rgba(243,244,220,0.5)] via-50% to-[rgba(0,0,0,0)] to-60%"></div>
            </a>
          </div>
        </div>
      ) : (
        <div className="relative">
          {theme === 'shrimpocalypse' ?
            <StaticImage
              src="../../images/hero-desktop-shrimpocalypse.png"
              alt="Crabs in a Bucket box art"
            />
          :
            <StaticImage
              src="../../images/hero-desktop.png"
              alt="Crabs in a Bucket box art"
            />
          }
          <div className="badge-mask -translate-y-[105%] animate-slide-in-top absolute top-[1%] right-[22.5%] w-[15%]">
            <a
              className="block"
              href={popInsiderUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../../images/popinsider.png"
                placeholder="none"
              />
              <div className="animate-shimmer -translate-x-full absolute top-0 left-0 w-full h-full bg-gradient-to-r from-[rgba(0,0,0,0)] from-40% via-[rgba(243,244,220,0.5)] via-50% to-[rgba(0,0,0,0)] to-60%"></div>
            </a>
          </div>
        </div>
      )}
    </header>
  );
};

export default Hero;
