import React, { useEffect, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import ChevronLeftIcon from '../../assets/icons/chevron-left.svg';
import ChevronRightIcon from '../../assets/icons/chevron-right.svg';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const MiniCrabSelector = ({ theme, setTheme, initialCrabIndex }) => {
  if (!initialCrabIndex) {
    initialCrabIndex = 0;
  }
  
  const data = useStaticQuery(query);
  const crabs = data.allCrabListJson.nodes;
  const crabImages = data.allFile.nodes;

  const [crabIndex, setCrabIndex] = useState(initialCrabIndex);
  const [chosenCrab, setChosenCrab] = useState(crabs[initialCrabIndex]);

  const previousCrabIndex = () => crabIndex-1 < 0 ? crabs.length-1 : crabIndex-1;
  const nextCrabIndex = () => (crabIndex+1) % crabs.length;

  const [previousCrab, setPreviousCrab] = useState(crabs[previousCrabIndex()]);
  const [nextCrab, setNextCrab] = useState(crabs[nextCrabIndex()]);

  useEffect(() => {
    setChosenCrab(crabs[crabIndex]);
    setPreviousCrab(crabs[previousCrabIndex()]);
    setNextCrab(crabs[nextCrabIndex()]);
  }, [crabIndex]);

  const crabImageMap = {};
  crabImages.forEach(image => crabImageMap[image.name] = getImage(image));
  crabs.forEach(crab => crab.image = crabImageMap[crab.name]);

  const handleRightArrowClick = () => {
    const nextIndex = nextCrabIndex();
    setCrabIndex(nextIndex);
    if (nextIndex > 21) {
      setTheme("shrimpocalypse");
      if (window.history.replaceState) {
        window.history.replaceState({}, null, '/shrimpocalypse');
      }
    } else {
      setTheme("base");
      if (window.history.replaceState) {
        window.history.replaceState({}, null, '/');
      }
    }
  }

  const handleLeftArrowClick = () => {
    const prevIndex = previousCrabIndex();
    setCrabIndex(prevIndex);
    if (prevIndex > 21) {
      setTheme("shrimpocalypse");
      if (window.history.replaceState) {
        window.history.replaceState({}, null, '/shrimpocalypse');
      }
    } else {
      setTheme("base");
      if (window.history.replaceState) {
        window.history.replaceState({}, null, '/');
      }
    }
  }


  return (
    <div>
      <div
        aria-label='Crab carousel'
        className="
          grid
          grid-cols-12
          pb-2
        "
      >
        <button
          aria-label="Previous crab"
          className='
            col-span-3
            self-center
            h-1/2
            max-h-20
          '
          onClick={handleLeftArrowClick}
        >
          <ChevronLeftIcon className={`
            h-full
            mx-auto
            ${theme === "shrimpocalypse" ? "stroke-white" : "stroke-brown-dark"}
          `}/>
        </button>
        <div className="
          col-span-6
          drop-shadow-hard
        ">
          <Link
            aria-label={`Read more about ${chosenCrab.fullname} and its combos`}
            to={`/crabs/${chosenCrab.name}`}
          >
            <GatsbyImage
              image={chosenCrab.image}
              alt={chosenCrab.fullname}
            />
          </Link>
            <div
              aria-hidden="true"
              className="hidden"
            >
              <GatsbyImage
                image={previousCrab.image}
                alt={nextCrab.fullname}
                loading="eager"
              />
              <GatsbyImage
                image={nextCrab.image}
                alt={nextCrab.fullname}
                loading="eager"
              />
            </div>
        </div>
        <button
          aria-label="Next crab"
          className="
            col-span-3
            self-center
            h-1/2
            max-h-20
          "
          onClick={handleRightArrowClick}
        >
          <ChevronRightIcon className={`
            h-full
            mx-auto
            ${theme === "shrimpocalypse" ? "stroke-white" : "stroke-brown-dark"}
          `}/>
        </button>
      </div>

      <div className='
        text-center
      '>
        <h2 className={`
          ${theme === "shrimpocalypse" ? "text-white" : "text-brown-dark"}
          text-3xl
        `}>
          {chosenCrab.fullname}
        </h2>
        <Link
          aria-label={`Read more about ${chosenCrab.fullname} and its combos`}
          to={`/crabs/${chosenCrab.name}`}
          className={`
            underline
            text-rondoblue-btn
            ${theme === "shrimpocalypse" && "text-rondoblue-btn-light"}
          `}
        >
          See combos →
        </Link>
      </div>

    </div>
  );
};

const query = graphql`
  query {
    allFile(filter: {sourceInstanceName: {eq: "crabDesigns"}}) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            width: 400
          )
        }
        name
      }
    }
    allCrabListJson {
      nodes {
        fullname
        name
      }
    }
  }
`

export default MiniCrabSelector;
