import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const SeabunnyShowcase = ({ children }) => {

  const seabunniesImg = "../../images/shrimpocalypse/seabunnies.png"

  return (
    <section className="
      w-full
      bg-seabunnies
    ">
      <div className="
        pt-12
        lg:pt-40
        xl-pt-40
        py-16
        px-8
        max-w-full
        md:max-w-[60rem]
        mx-auto
      ">
        <p className="
          pb-8
          text-2xl
          md:text-3xl
          max-w-full
          md:max-w-[40rem]
          text-white
          text-center
          mx-auto
        ">
          And last but not least, the <b>SEABUNNIES</b>! Use their Bunnycrossing abilities to discard more cards during ascending runs!
        </p>

         <Link
          to="/crabs/seabunnies" 
        >
          <StaticImage
            src={seabunniesImg}
            alt="Sea bunnies"
            layout="fullWidth"
          />
        </Link>
      </div>
      {children}
    </section>
  )
}

export default SeabunnyShowcase;
