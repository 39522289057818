import React from 'react';
import Layout from '../components/Layout';
import { StaticImage } from 'gatsby-plugin-image';

const TheGame = () => {
  // hold up, did you just lose the game?
  return (
    <Layout>
      <div className="
        bg-gradient-to-b
        w-screen
        from-gamebg-1
        via-gamebg-2
        to-gamebg-4
        -mt-20
      ">
        <div className="
          flex
          justify-center
          pb-6
          pt-6
        ">
          <StaticImage
            src="../images/joker-crabs/crab-designs/base-game/lobster.png"
          />
        </div>
        <h2 className="
          mx-auto
          text-7xl
          text-[#000]
          pb-6
          text-stroke
        ">
          You just lost the game.
        </h2>
      </div>
    </Layout>
  );
}

export default TheGame;
