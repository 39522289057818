import * as React from "react"

import Layout from "../components/Layout";
import MiniCrabSelector from "../components/index/MiniCrabSelector";
import OceanSection from "../components/OceanSection";
import Reviews from "../components/index/Reviews";
import SandSection from "../components/SandSection";
import YoutubeEmbed from "../components/YoutubeEmbed";
import Hero from "../components/index/Hero";
import Metadata from "../components/Metadata";
import { useState } from "react";
import ShrimpShowcase from "../components/index/ShrimpShowcase";
import SeabunnyShowcase from "../components/index/SeabunnyShowcase";
import WaveSvg from '../assets/wave-lg.svg';

const Homepage = ({ pageContext }) => {
  
  const [theme, setTheme] = useState(pageContext.theme ? pageContext.theme : "base");

  return (
    <Layout theme={theme} >
      <Metadata
        title={'Home'}
        slug={''}
      />
      <main>
        <Hero theme={theme}/>
        <section>
          <div className="
            bg-salmon
            text-2xl
            md:text-3xl
            text-white
            py-6
            text-center
            flex
            flex-col
            gap-y-4
            font-bold
          ">
            <p className="
              max-w-sm
              sm:max-w-full
              mx-auto
              block
            ">
              We are counting on YOU to lead the way!
            </p>
            <p>
              Here's how to play:
            </p>
          </div>
          <div className="
            bg-salmon-light
            py-8
          ">
            {theme === "shrimpocalypse" ? 
              <YoutubeEmbed src="https://www.youtube.com/watch?v=bMPUyW0Jx70"/>
            :
              <YoutubeEmbed src="https://www.youtube.com/watch?v=TPZ_jlDCaY8"/>
            }
          </div>
        </section>
        

        <SandSection theme={theme}>
          <div className={`
            md:grid
            grid-cols-12
            md:pt-20
            md:w-3xl
            lg:max-w-5xl
            pt-20
            lg:pt-40
            xl-pt-40
            ${theme === "shrimpocalypse" && "pb-20 md:pb-20 lg:pb-40"}
            max-w-[80%]<></>
            mx-auto
          `}>
            <p className={`
              md:pb-0
              pb-12
              md:max-w-[15rem]
              md:text-3xl
              lg:text-4xl
              max-w-[80%]
              w-60
              text-center
              text-2xl
              font-medium
              col-span-4
              m-auto
              ${theme === "shrimpocalypse" ? "text-white" : "text-brown-dark"}
            `}>
              Use the power of the Joker Crabs wisely.
            </p>
            <div className="
              col-span-8
            ">
              <MiniCrabSelector theme={theme} setTheme={setTheme} initialCrabIndex={pageContext.initialCrabIndex} />
            </div>
          </div>
        {theme === "base" &&
          <WaveSvg className='
            fill-ocean-light
            -m-px
          '/>
        }
        </SandSection>

        {theme === "shrimpocalypse" &&
          <>
            <ShrimpShowcase />

            <SeabunnyShowcase>
              <WaveSvg className='
                fill-ocean-light-shrimpocalypse
                -m-px
              '/>
            </SeabunnyShowcase>
          </>
        }

        <OceanSection theme={theme}>
          <Reviews/>
        </OceanSection>

      </main>
    </Layout>
  )
}

export default Homepage;
